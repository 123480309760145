<template>
  <v-container
    fluid
    style=" background:rgba(255,255,255,0.9);min-width:100%; min-height:95vh; padding:10px 20px;position:relative;"
  >
    <h6
      @click="back"
      style="border:1px solid rgba(0,0,0,0.3); border-radius:5px; position:absolute; width:70px; cursor:pointer;"
    >
      <v-icon style="position:relative; top:-1px;">mdi-chevron-left</v-icon>back
    </h6>
    <div style="position:absolute; top:15px; right:20px;">
      <v-btn
        @click="trackVehicle"
        color="primary"
        text
        small
        style="margin-right:10px;"
      >
        Lacak Kendaraan
      </v-btn>
      <v-btn @click="viewHistory" color="primary" text small>
        History
      </v-btn>
    </div>
    <div v-if="detail !== null" style="margin-bottom:65px;">
      <h4
        style="
  margin: auto;
  width: 50%;
  font-weight: bold;
  text-align: center;
  font-size: 20px;"
      >
        DETAIL SURAT JALAN
      </h4>

      <p
        v-if="detail.order_details !== null"
        style="margin:auto 0;text-align:center;"
      >
        No. {{ detail.order_details.order_no }}
        <v-tooltip bottom color="grey">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              x-small
              text
              icon
              color="grey darken-2"
              @click="refreshPage"
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>refresh page</span>
        </v-tooltip>
      </p>
      <v-row v-if="detail.order_details !== null" no-gutters>
        <v-col cols="8" style="padding:5px;">
          <v-row>
            <v-col cols="12">
              <v-card elevation="1" style="padding:5px;">
                <div style="margin:5px 0">
                  <div
                    style="margin-left:5px; font-size:14px; font-weight:bold;margin-bottom:10px;border-bottom:1px solid black;"
                  >
                    DETAIL TRANSAKSI
                  </div>
                  <v-row no-gutters>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Tipe Transaksi
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.order_details.order_type.name"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            No. Surat Jalan
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.order_details.odoo_order_no"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Tgl. Transaksi
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.order_details.order_date"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Perusahaan
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.order_details.company.name"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            No. SO
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.so_no"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Mix Referensi SO
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.so_mix_ref"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Muat Awal
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.order_details.loading"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Muat Selanjutnya
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.order_details.other_loading"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Destinasi
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.order_details.unloading"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Zona
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.order_details.zone"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Status
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0; border:1px solid">
                          <div
                            class="d-flex justify-center align-center"
                            :style="
                              `padding: 0 10px; height:35px; border:1px solid rgba(0,0,0,0.3); background:${
                                statusColor(detail.order_details.status.id)
                                  .background
                              };`
                            "
                          >
                            <p
                              :style="
                                `margin:0; font-size:1vw; font-weight:bold; color:${
                                  statusColor(detail.order_details.status.id)
                                    .text
                                };`
                              "
                            >
                              {{ statusName(detail.order_details.status.id) }}
                            </p>
                          </div>
                        </v-col>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div style="margin:5px 0">
                  <div
                    style="margin-left:5px; font-size:14px; font-weight:bold;margin-bottom:10px;border-bottom:1px solid black;"
                  >
                    DATA CUSTOMER
                  </div>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Customer
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.customer_data.customer_name"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Sub Customer
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.customer_data.sub_customer_name"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Alamat Customer
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-textarea
                            :value="detail.customer_data.customer_address"
                            dense
                            :auto-grow="true"
                            rows="1"
                            readonly
                            style="font-size:14px;"
                            clear-icon="mdi-close-circle"
                          ></v-textarea>
                        </v-col>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div style="margin:5px 0">
                  <div
                    style="margin-left:5px; font-size:14px; font-weight:bold;margin-bottom:10px;border-bottom:1px solid black;"
                  >
                    DATA KENDARAAN
                  </div>
                  <v-row no-gutters>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Supir
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.driver_vehicle.driver_name"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Jenis Kendaraan
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.driver_vehicle.vehicle_type"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Nomor Polisi
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.driver_vehicle.license_plate"
                          />
                        </v-col>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div style="margin:5px 0">
                  <div
                    style="margin-left:5px; font-size:14px; font-weight:bold;margin-bottom:10px;border-bottom:1px solid black;"
                  >
                    JARAK (Berdasarkan google map)
                  </div>
                  <v-row no-gutters>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Standard
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.distance.standard + ' (Km)'"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Aktual
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="detail.distance.actual + ' (Km)'"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Durasi
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            readonly
                            :value="
                              detail.distance.duration.toFixed(2) + ' (min)'
                            "
                          />
                        </v-col>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div style="margin:5px 0">
                  <div
                    style="margin-left:5px; font-size:14px; font-weight:bold;margin-bottom:10px;border-bottom:1px solid black;"
                  >
                    BS
                  </div>
                  <v-row no-gutters style="padding-top:10px;">
                    <v-col cols="4">
                      <div style="padding: 5px 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Tipe BS
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-text-field
                            style="font-size:14px;"
                            dense
                            :value="
                              detail.bs.carry_bs == 1
                                ? 'Gudang'
                                : detail.bs.carry_bs == 2
                                ? 'Mobil'
                                : '-'
                            "
                            readonly
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Catatan
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-textarea
                            :value="detail.bs.note_bs"
                            dense
                            :auto-grow="true"
                            rows="1"
                            readonly
                            style="font-size:14px;"
                            clear-icon="mdi-close-circle"
                          ></v-textarea>
                        </v-col>
                      </div>
                    </v-col>

                    <v-col cols="12" v-if="detail.bs.photo_bs !== null">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                        cursor: pointer;
                      "
                          >
                            Foto
                          </p>
                        </v-col>
                        <v-col
                          cols="12"
                          style="padding: 0;"
                          class="d-flex justify-center align-center flex-column"
                        >
                          <v-img
                            :src="
                              `${image}esanqua/expedition/photos/bs/${detail.bs.photo_bs}`
                            "
                            :lazy-src="
                              `https://picsum.photos/10/6?image=${1 * 5 + 10}`
                            "
                            contain
                            style="width:50px; height:100px; border-radius:5px; cursor:pointer; border:1px solid black;"
                            class="grey lighten-2"
                            @click.stop="viewPhoto(detail.bs.photo_bs)"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <a
                            href="#"
                            @click.stop="viewPhoto(detail.bs.photo_bs)"
                            style="padding:0; margin:0; font-size:14px; cursor:pointer; margin-left:10px;"
                            >{{ detail.bs.photo_bs }}</a
                          >
                        </v-col>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" style="padding:5px;">
          <v-row>
            <v-col cols="12">
              <v-card elevation="1" style="padding:5px;">
                <div
                  style="margin-left:5px; font-size:14px; font-weight:bold;margin-bottom:10px;border-bottom:1px solid black;"
                >
                  DETAIL BARANG
                </div>
                <v-data-table
                  mobile-breakpoint="0"
                  :headers="itemTableHeaders"
                  :items="detail.ordered_items"
                  :items-per-page="10"
                  class="elevation-1 mx-auto"
                  hide-default-footer
                >
                </v-data-table>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card elevation="1" style="padding:5px;">
                <div
                  style="font-size:14px; font-weight:bold; margin-bottom:10px; border-bottom:1px solid black;"
                >
                  DETAIL TAGIHAN
                </div>
                <div style="padding:0 5px 5px 5px; margin-bottom:10px;">
                  <div class="d-flex justify-content-between align-center">
                    <p
                      style="width:50%; margin:auto 0;display:flex; justify-content:space-between; font-size:14px;"
                    >
                      <span>
                        {{
                          detail.driver_vehicle.vehicle_type +
                            ' (' +
                            detail.order_details.zone +
                            ') '
                        }}</span
                      >
                      <span style="font-weight:bolder:margin-left:5px;">:</span>
                    </p>
                    <p
                      class="d-flex justify-end"
                      style="width: 40%; font-size:14px; border-bottom:1px dotted black; padding:0 10px;margin:auto 0;"
                    >
                      <span
                        >Rp. {{ priceFormat(detail.standard_price_zone) }}</span
                      >
                    </p>
                  </div>
                </div>
                <div style="padding:0 5px 5px 5px; margin-bottom:10px;">
                  <div class="d-flex justify-content-between align-center">
                    <p
                      style="width:50%; margin:auto 0;display:flex; justify-content:space-between; font-size:14px; font-weight:bold;"
                    >
                      <span>
                        Harga standar
                      </span>
                      <!-- <span style="font-weight:bolder:margin-left:5px;">:</span> -->
                    </p>
                    <!-- <p
                      class="d-flex justify-end"
                      style="width: 40%; font-size:14px; border-bottom:1px dotted black; padding:0 10px;margin:auto 0;"
                    >
                      <span
                        >Rp. {{ priceFormat(detail.standard_price_zone) }}</span
                      >
                    </p> -->
                  </div>
                  <div class="d-flex justify-content-between align-center">
                    <p
                      style="width:50%; margin:auto 0;display:flex; justify-content:space-between; font-size:14px;"
                    >
                      <span>
                        + {{ detail.standard_additional_km }} Km ( x Rp.{{
                          priceFormat(detail.standard_price_per_km)
                        }})</span
                      >
                      <span style="font-weight:bolder:margin-left:5px;">:</span>
                    </p>
                    <p
                      class="d-flex justify-end"
                      style="width: 40%; font-size:14px; border-bottom:1px dotted black; padding:0 10px;margin:auto 0;"
                    >
                      <span
                        >Rp.
                        {{
                          priceFormat(
                            detail.standard_price_per_km *
                              detail.standard_additional_km
                          )
                        }}</span
                      >
                    </p>
                  </div>
                  <div class="d-flex justify-content-between align-center">
                    <p
                      style="width:50%; margin:auto 0;display:flex; justify-content:space-between; font-size:14px;"
                    >
                      <span> Total harga</span>
                      <span style="font-weight:bolder:margin-left:5px;">:</span>
                    </p>
                    <p
                      class="d-flex justify-end"
                      style="width: 40%; font-size:14px; border-bottom:1px dotted black; padding:0 10px;margin:auto 0;"
                    >
                      <span
                        >Rp.
                        {{ priceFormat(detail.total_standard_price) }}</span
                      >
                    </p>
                  </div>
                </div>
                <div style="padding:0 5px 5px 5px; margin-bottom:20px;">
                  <div class="d-flex justify-content-between align-center">
                    <p
                      style="width:50%; margin:auto 0;display:flex; justify-content:space-between; font-size:14px; font-weight:bold;"
                    >
                      <span> Harga aktual</span>
                    </p>
                  </div>
                  <div class="d-flex justify-content-between align-center">
                    <p
                      style="width:50%; margin:auto 0;display:flex; justify-content:space-between; font-size:14px;"
                    >
                      <span> + {{ detail.actual_additional_km }} Km</span>
                      <span style="font-weight:bolder:margin-left:5px;">:</span>
                    </p>
                    <p
                      class="d-flex justify-end"
                      style="width: 40%; font-size:14px; border-bottom:1px dotted black; padding:0 10px;margin:auto 0;"
                    >
                      <span
                        >Rp.
                        {{
                          detail.actual_additional_km *
                            detail.actual_price_additional_km
                        }}</span
                      >
                    </p>
                  </div>
                  <div class="d-flex justify-content-between align-center">
                    <p
                      style="width:50%; margin:auto 0;display:flex; justify-content:space-between; font-size:14px;"
                    >
                      <span> Total harga</span>
                      <span style="font-weight:bolder:margin-left:5px;">:</span>
                    </p>
                    <p
                      class="d-flex justify-end"
                      style="width: 40%; font-size:14px; border-bottom:1px dotted black; padding:0 10px;margin:auto 0;"
                    >
                      <span
                        >Rp. {{ priceFormat(detail.total_actual_price) }}</span
                      >
                    </p>
                  </div>
                </div>
                <div style="padding:0 5px 5px 5px; margin-bottom:10px;">
                  <div class="d-flex justify-content-between align-center">
                    <p
                      style="width:50%; margin:auto 0;display:flex; justify-content:space-between; font-size:14px;"
                    >
                      <span> Perbedaan harga</span>
                      <span style="font-weight:bolder:margin-left:5px;">:</span>
                    </p>
                    <p
                      class="d-flex justify-end"
                      style="width: 40%; font-size:14px; border-bottom:1px dotted black; padding:0 10px;margin:auto 0;"
                    >
                      <span
                        >Rp.
                        {{
                          priceFormat(
                            Math.abs(
                              detail.total_actual_price -
                                detail.total_standard_price
                            )
                          )
                        }}</span
                      >
                    </p>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card elevation="1" style="padding:5px;">
                <div
                  style="margin-left:5px; font-size:14px; font-weight:bold;margin-bottom:10px;border-bottom:1px solid black;"
                >
                  REKAPAN UANG JALAN
                </div>
                <v-row no-gutters>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Jarak Tempuh
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="
                            detail.order_details.summary_travel_cost
                              .total_mileage + ' (Km)'
                          "
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          BBM
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="
                            'Rp. ' +
                              priceFormat(
                                Math.round(
                                  detail.order_details.summary_travel_cost
                                    .total_fuel_cost
                                )
                              )
                          "
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Toll
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="
                            'Rp. ' +
                              priceFormat(
                                Math.round(
                                  detail.order_details.summary_travel_cost
                                    .total_toll_cost
                                )
                              )
                          "
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Muat Barang
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="
                            'Rp. ' +
                              priceFormat(
                                Math.round(
                                  detail.order_details.summary_travel_cost
                                    .total_load_cost
                                )
                              )
                          "
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Bongkar Barang
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="
                            'Rp. ' +
                              priceFormat(
                                Math.round(
                                  detail.order_details.summary_travel_cost
                                    .total_unload_cost
                                )
                              )
                          "
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          MEL
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="
                            'Rp. ' +
                              priceFormat(
                                Math.round(
                                  detail.order_details.summary_travel_cost
                                    .total_mel_cost
                                )
                              )
                          "
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Lain-Lain
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="
                            'Rp. ' +
                              priceFormat(
                                Math.round(
                                  detail.order_details.summary_travel_cost
                                    .total_other_cost
                                )
                              )
                          "
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Rate Supir
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="
                            'Rp. ' +
                              priceFormat(
                                Math.round(
                                  detail.order_details.summary_travel_cost
                                    .total_driver_rate
                                )
                              )
                          "
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Rate Kenek
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="
                            'Rp. ' +
                              priceFormat(
                                Math.round(
                                  detail.order_details.summary_travel_cost
                                    .total_assistance_driver_rate
                                )
                              )
                          "
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                        >
                          Total
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0;">
                        <v-text-field
                          style="font-size:14px;"
                          dense
                          readonly
                          :value="
                            'Rp. ' +
                              priceFormat(
                                Math.round(
                                  detail.order_details.summary_travel_cost
                                    .total_total_cost
                                )
                              )
                          "
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters style="padding: 0 10px">
                      <v-col
                        cols="6"
                        style="padding: 0; margin:auto;"
                        class="d-flex"
                      >
                        <p
                          style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                      "
                        >
                          TOTAL BIAYA
                        </p>
                        <p
                          style="
                        margin: 0 0 0 15px;
                        font-size: 12px;
                      "
                        >
                          :
                        </p>
                      </v-col>
                      <v-col cols="6" style="padding: 0;">
                        <v-text-field
                          style="font-size:16px; font-weight:bold;"
                          dense
                          readonly
                          :value="
                            'Rp. ' +
                              priceFormat(
                                Math.round(
                                  detail.order_details.summary_travel_cost
                                    .total_rounding_total_cost
                                )
                              )
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" style="position:relative; top:-15px;">
              <v-btn
                @click="travelCostDetail"
                outlined
                elevation="0"
                color="primary"
                class="
                      white--text font-weight-bold ma-1
                    "
                style="font-size:12px;"
                small
              >
                Detail uang jalan
              </v-btn>
              <v-btn
                @click="addAdditionalCost"
                outlined
                elevation="0"
                color="primary"
                class="
                      white--text font-weight-bold ma-1
                    "
                style="font-size:12px;"
                small
              >
                + biaya tambahan
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="position:fixed; top:0; left:0; right:0; bottom:0;z-index:6; background-color:rgba(255,255,255,0.8);"
    >
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-dialog v-model="additionalDialogTable" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Catatan Uang Jalan
        </v-card-title>

        <v-card-text
          class="d-flex justify-center flex-column"
          style="margin-top:10px;"
        >
          <v-row no-gutters>
            <v-col v-if="detail !== null" cols="12" style="padding: 0;">
              <v-data-table
                :items="detail.travel_cost"
                mobile-breakpoint="0"
                fixed-header
                :headers="headers"
                dense
                style="cursor: pointer;"
              >
                <template v-slot:[`item.travel_cost_type`]="{ item }">
                  <div
                    :style="
                      `font-size:11px; padding: 0; display: flex; flex-direction: row; color:${
                        item.travel_cost_type === 1
                          ? 'black'
                          : item.travel_cost_type === 4
                          ? 'red'
                          : ''
                      };`
                    "
                  >
                    {{ travelCostType(item.travel_cost_type).name }}
                  </div>
                </template>
                <template v-slot:[`item.other_cost`]="{ item }">
                  <div
                    :style="
                      `font-size:11px; padding: 0; display: flex; flex-direction: row; color:${
                        travelCostType(item.travel_cost_type).color
                      };`
                    "
                  >
                    {{ priceFormat(item.other_cost) }}
                  </div>
                </template>
                <template v-slot:[`item.rounding_total_cost`]="{ item }">
                  <div
                    :style="
                      `font-size:11px; padding: 0; display: flex; flex-direction: row; color:${
                        travelCostType(item.travel_cost_type).color
                      };`
                    "
                  >
                    Rp.
                    {{ priceFormat(item.rounding_total_cost) }}
                  </div>
                </template>
                <template v-slot:[`item.total_cost`]="{ item }">
                  <div
                    :style="
                      `font-size:11px; padding: 0; display: flex; flex-direction: row; color:${
                        travelCostType(item.travel_cost_type).color
                      };`
                    "
                  >
                    {{ priceFormat(item.total_cost) }}
                  </div>
                </template>
                <template v-slot:[`item.driver_rate`]="{ item }">
                  <div
                    :style="
                      `font-size:11px; padding: 0; display: flex; flex-direction: row; color:${
                        travelCostType(item.travel_cost_type).color
                      };`
                    "
                  >
                    {{ priceFormat(item.driver_rate) }}
                  </div>
                </template>
                <template v-slot:[`item.assistance_driver_rate`]="{ item }">
                  <div
                    :style="
                      `font-size:11px; padding: 0; display: flex; flex-direction: row; color:${
                        travelCostType(item.travel_cost_type).color
                      };`
                    "
                  >
                    {{ priceFormat(item.assistance_driver_rate) }}
                  </div>
                </template>
                <template v-slot:[`item.mel_cost`]="{ item }">
                  <div
                    :style="
                      `font-size:11px; padding: 0; display: flex; flex-direction: row; color:${
                        travelCostType(item.travel_cost_type).color
                      };`
                    "
                  >
                    {{ priceFormat(item.mel_cost) }}
                  </div>
                </template>
                <template v-slot:[`item.load_cost`]="{ item }">
                  <div
                    :style="
                      `font-size:11px; padding: 0; display: flex; flex-direction: row; color:${
                        travelCostType(item.travel_cost_type).color
                      };`
                    "
                  >
                    {{ priceFormat(item.load_cost) }}
                  </div>
                </template>
                <template v-slot:[`item.unload_cost`]="{ item }">
                  <div
                    :style="
                      `font-size:11px; padding: 0; display: flex; flex-direction: row; color:${
                        travelCostType(item.travel_cost_type).color
                      };`
                    "
                  >
                    {{ priceFormat(item.unload_cost) }}
                  </div>
                </template>
                <template v-slot:[`item.toll_cost`]="{ item }">
                  <div
                    :style="
                      `font-size:11px; padding: 0; display: flex; flex-direction: row; color:${
                        travelCostType(item.travel_cost_type).color
                      };`
                    "
                  >
                    {{ priceFormat(item.toll_cost) }}
                  </div>
                </template>
                <template v-slot:[`item.fuel_cost`]="{ item }">
                  <div
                    :style="
                      `font-size:11px; padding: 0; display: flex; flex-direction: row; color:${
                        travelCostType(item.travel_cost_type).color
                      };`
                    "
                  >
                    {{ priceFormat(item.fuel_cost) }}
                  </div>
                </template>
                <template v-slot:[`item.mileage`]="{ item }">
                  <div
                    :style="
                      `font-size:11px; padding: 0; display: flex; flex-direction: row; color:${
                        travelCostType(item.travel_cost_type).color
                      };`
                    "
                  >
                    {{ item.mileage }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="d-flex justify-content-between">
          <v-btn
            @click="additionalDialogTable = false"
            color="error"
            text
            outlined
          >
            close
          </v-btn>
          <v-btn @click="addAdditionalCost" color="primary" text type="button">
            + biaya tambahan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="additionalDialogForm" width="500" persistent>
      <v-form
        v-if="form !== null"
        lazy-validation
        ref="entryForm2"
        @submit.prevent="submitForm"
        style="position: relative;"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Formulir Biaya Tambahan / Koreksi
          </v-card-title>

          <v-card-text
            class="d-flex justify-center flex-column"
            style="margin-top:10px;"
          >
            <v-row no-gutters>
              <v-col cols="6" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Biaya Tambahan / Koreksi
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-select
                      v-model="form.travel_cost_type"
                      :items="[
                        { id: 3, name: 'Tambahan' },
                        { id: 4, name: 'Koreksi' }
                      ]"
                      dense
                      outlined
                      item-text="name"
                      item-value="id"
                      return-id
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Jarak Tempuh
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.mileage"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      BBM
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.fuel_cost"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      TOLL
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.toll_cost"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Muat Barang
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.load_cost"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Bongkar Barang
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.unload_cost"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      MEL
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.mel_cost"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Lain-lain
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.other_cost"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Rate Supir
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.driver_rate"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Rate Kenek
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.assistance_driver_rate"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Total
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.total_cost"
                      dense
                      outlined
                      readonly
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Total Pembulatan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.rounding_total_cost"
                      dense
                      outlined
                      type="number"
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" style="padding: 0;">
                <div style="padding: 0 10px;">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      catatan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-textarea
                      dense
                      auto-grow
                      rows="3"
                      outlined
                      v-model="form.description"
                    />
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              @click="additionalDialogFormClose"
              color="error"
              text
              outlined
              type="reset"
              :loading="loading"
            >
              close
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              text
              outlined
              type="submit"
              :loading="loading"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="imageDialog" persistent>
      <div style="position:relative;">
        <v-img
          style="position:relative;"
          contain
          :src="
            imageGeneratedLink !== null
              ? imageGeneratedLink
              : 'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-jahsors8yollrrbjwsyx7.png'
          "
          @error="imgError"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>

        <v-btn
          style="position:fixed; z-index:1; top:50px; right:50px; font-weight:bolder;"
          fab
          x-small
          color="black"
          @click="imageDialog = false"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
        <v-btn
          @click="downloadImage"
          style="position:fixed; z-index:1; top:55px; right:100px;"
          x-small
          color="green"
          class="white--text"
        >
          Download
          <v-icon small color="white">mdi-download</v-icon>
        </v-btn>
      </div>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios'
// import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'ordertransactiondetail',
  components: {},
  data() {
    return {
      expedition: buildType.apiURL('expedition'),
      eSanqua: buildType.apiURL('esanqua'),
      image: buildType.apiURL('image'),
      trackingUrl: buildType.urlConfig('tracking'),
      build: process.env.VUE_APP_BUILD_TYPE,
      loading: false,
      detail: null,
      additionalDialogTable: false,
      additionalDialogForm: false,
      itemTableHeaders: [
        { text: 'Nama', value: 'product_name', align: 'left' },
        { text: 'Qty', value: 'qty', align: 'left' },
        { text: 'Satuan', value: 'uom_name', align: 'left' }
      ],

      headers: [
        {
          text: 'Tipe',
          value: 'travel_cost_type',
          align: 'left',
          sortable: false
        },
        {
          text: 'Jarak (km)',
          value: 'mileage',
          align: 'left',
          sortable: false
        },
        {
          text: 'BBM',
          value: 'fuel_cost',
          align: 'left',
          sortable: false
        },
        {
          text: 'Toll',
          value: 'toll_cost',
          align: 'left',
          sortable: false
        },
        {
          text: 'Muat',
          value: 'load_cost',
          align: 'left',
          sortable: false
        },
        {
          text: 'Bongkar',
          value: 'unload_cost',
          align: 'left',
          sortable: false
        },
        {
          text: 'MEL',
          value: 'mel_cost',
          align: 'left',
          sortable: false
        },
        {
          text: 'Rate Supir',
          value: 'driver_rate',
          align: 'left',
          sortable: false
        },
        {
          text: 'Rate Kenek',
          value: 'assistance_driver_rate',
          align: 'left',
          sortable: false
        },
        {
          text: 'Lain-lain',
          value: 'other_cost',
          align: 'left',
          sortable: false
        },
        {
          text: 'Total',
          value: 'total_cost',
          align: 'left',
          sortable: false
        },
        {
          text: 'Pembulatan',
          value: 'rounding_total_cost',
          align: 'left',
          sortable: false
        },
        {
          text: 'Desc',
          value: 'description',
          align: 'left',
          sortable: false
        }
      ],
      form: {
        mileage: 0,
        fuel_cost: 0,
        toll_cost: 0,
        load_cost: 0,
        unload_cost: 0,
        mel_cost: 0,
        other_cost: 0,
        driver_rate: 0,
        assistance_driver_rate: 0,
        without_fuel_cost: 0,
        total_cost: 0,
        rounding_total_cost: 0,
        description: ''
      },
      imageGeneratedLink: null,
      imageDialog: false
    }
  },
  mounted() {
    this.getDetail()
  },
  watch: {
    'form.fuel_cost'() {
      this.calculate()
    },
    'form.toll_cost'() {
      this.calculate()
    },
    'form.load_cost'() {
      this.calculate()
    },
    'form.unload_cost'() {
      this.calculate()
    },
    'form.mel_cost'() {
      this.calculate()
    },
    'form.other_cost'() {
      this.calculate()
    },
    'form.driver_rate'() {
      this.calculate()
    },
    'form.assistance_driver_rate'() {
      this.calculate()
    },
    'form.without_fuel_cost'() {
      this.calculate()
    }
  },
  methods: {
    back() {
      this.detail = null
      this.$router.push('/expedition/ordertransaction')
    },
    refreshPage() {
      this.detail = null
      this.getDetail()
    },
    async getDetail() {
      this.loading = true
      await axios
        // .get(
        //   `http://localhost:1196/api/esanqua/expedition/v1/transaction/detail/${this.$route.params.id}`
        // )
        .get(`${this.expedition}transaction/detail/${this.$route.params.id}`)
        .then(res => {
          console.log(res.data.data)
          setTimeout(() => {
            this.detail = res.data.data
            this.loading = false
          }, 1000)
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    rowClick() {},
    addAdditionalCost() {
      setTimeout(() => {
        this.additionalDialogForm = true
      }, 500)
    },
    travelCostDetail() {
      setTimeout(() => {
        this.additionalDialogTable = true
      }, 500)
    },
    submitForm() {
      // this.rules = {
      //   nameRules: [v => !!v || 'Name is required'],
      //   priceRules: [v => !!v || 'Price is required']
      // }
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm2.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: 'add',
        order_id: this.detail.id,
        mileage: this.form.mileage,
        fuel_cost: this.form.fuel_cost,
        toll_cost: this.form.toll_cost,
        load_cost: this.form.load_cost,
        unload_cost: this.form.unload_cost,
        mel_cost: this.form.mel_cost,
        other_cost: this.form.other_cost,
        driver_rate: this.form.driver_rate,
        assistance_driver_rate: this.form.assistance_driver_rate,
        without_fuel_cost: this.form.without_fuel_cost,
        total_cost: this.form.total_cost,
        rounding_total_cost: this.form.rounding_total_cost,
        description: this.form.description,
        travel_cost_type: this.form.travel_cost_type
      }
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.expedition}transaction/travel_cost/save`, form)
        .then(res => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.getDetail()
            const self = this
            setTimeout(() => {
              self.additionalDialogFormClose()
            }, this.showMsgDialog('success', res.data.status_msg, false))
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              return this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              return this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    additionalDialogFormClose() {
      this.form = {
        mileage: 0,
        fuel_cost: 0,
        toll_cost: 0,
        load_cost: 0,
        unload_cost: 0,
        mel_cost: 0,
        other_cost: 0,
        driver_rate: 0,
        assistance_driver_rate: 0,
        without_fuel_cost: 0,
        total_cost: 0,
        rounding_total_cost: 0,
        description: '',
        travel_cost_type: 1
      }
      setTimeout(() => {
        this.additionalDialogForm = false
      }, 300)
    },
    calculate() {
      const total =
        Number(this.form.fuel_cost) +
        Number(this.form.toll_cost) +
        Number(this.form.load_cost) +
        Number(this.form.unload_cost) +
        Number(this.form.mel_cost) +
        Number(this.form.other_cost) +
        Number(this.form.driver_rate) +
        Number(this.form.assistance_driver_rate) +
        Number(this.form.without_fuel_cost)

      this.form.total_cost = total
      this.form.rounding_total_cost = total
    },
    trackVehicle() {
      window.open(
        `${this.trackingUrl}${this.detail.driver_vehicle.license_plate
          .split(' ')
          .join('+')}`,
        'blank'
      )
    },
    viewHistory() {
      window.open(`${this.trackingUrl}history/${this.detail.id}`, 'blank')
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    priceFormat(val) {
      if (val !== undefined) {
        if (val !== null) {
          return val.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
        }
      }
      return ''
    },
    travelCostType(val) {
      switch (val) {
        case 1:
          return { name: 'Isi', color: 'black' }
        case 2:
          return { name: 'Kosong', color: 'black' }
        case 3:
          return { name: 'Tambahan', color: 'black' }
        case 4:
          return { name: 'Koreksi', color: 'red' }
      }
    },
    statusName(val) {
      switch (Number(val)) {
        case 0:
          return 'MENUNGGU'
        case 1:
          return 'MENUJU TEMPAT MUAT'
        case 2:
          return 'SAMPAI DITEMPAT MUAT'
        case 3:
          return 'MULAI MEMUAT BARANG'
        case 4:
          return 'MENUJU TEMPAT MUAT (2)'
        case 5:
          return 'SAMPAI DITEMPAT MUAT (2)'
        case 6:
          return 'MULAI MEMUAT BARANG (2)'
        case 7:
          return 'MENUJU TEMPAT DESTINASI'
        case 8:
          return 'SAMPAI DI TEMPAT DESTINASI'
        case 9:
          return 'MULAI BONGKAR BARANG'
        case 10:
          return 'SELESAI'
        case 11:
          return 'KEMBALI KE GARASI'
        default:
          break
      }
    },
    statusColor(val) {
      switch (Number(val)) {
        case 0:
          return {
            background: 'orange',
            text: 'black'
          }
        case 1:
          return {
            background: 'blue',
            text: 'white'
          }
        case 2:
          return {
            background: 'blue',
            text: 'white'
          }
        case 3:
          return {
            background: 'blue',
            text: 'white'
          }
        case 4:
          return {
            background: 'blue',
            text: 'white'
          }
        case 5:
          return {
            background: 'blue',
            text: 'white'
          }
        case 6:
          return {
            background: 'blue',
            text: 'white'
          }
        case 7:
          return {
            background: 'blue',
            text: 'white'
          }
        case 8:
          return {
            background: 'blue',
            text: 'white'
          }
        case 9:
          return {
            background: 'blue',
            text: 'white'
          }
        case 10:
          return {
            background: 'green',
            text: 'white'
          }
        case 11:
          return {
            background: 'green',
            text: 'white'
          }
        default:
          return {
            background: 'white',
            text: 'black'
          }
      }
    },
    imgError() {
      console.log('error image')
      // this.isImgError = true
      this.imageDialog = false
      this.showMsgDialog('error', 'Image Error', false)
      // this.imageLink =
      //   'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-jahsors8yollrrbjwsyx7.png'
    },
    async downloadImage() {
      if (this.imageGeneratedLink !== null) {
        this.loading = true
        await axios
          .get(`${this.imageGeneratedLink}`)
          .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            // const url = `${this.image}esanqua/expedition/photos/bs/${val}`

            window.open(url, '_blank')
            const link = document.createElement('a')
            link.href = url

            link.setAttribute('download', `${this.detail.bs.photo_bs}`)
            document.body.appendChild(link)
            link.click()
            this.loading = false
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
    },

    async viewPhoto(val) {
      if (val !== null) {
        this.imageGeneratedLink = null

        const url = `${this.image}esanqua/expedition/photos/bs/${val}`
        this.imageGeneratedLink = url
        setTimeout(() => {
          this.imageDialog = true
        }, 300)
      } else {
        this.showMsgDialog('warning', 'Image not found', false)
      }
    }
  }
}
</script>
